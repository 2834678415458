import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "@timglenn123/single-spa-layout-cv-fork";
import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const routeData = {
  props: {
    queryClient,
  },
  loaders: {},
};

const routes = constructRoutes(
  document.querySelector("#single-spa-layout"),
  routeData
);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    
    console.log(name);
  
    return System.import(name);
  
    /* if (name === '@Cityverse/cityverse-auth') {
       return System.import(name);
     }
     if ((window.location.pathname === '/home' && name === '@Cityverse/cityverse-homespace') ||
         (window.location.pathname === '/news' && name !== '@Cityverse/cityverse-homespace')) {
       if (window.location.pathname === '/home') {
         document.body.classList.add('homespace');
       }
       return System.import(name);
     }*/
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();

start({
  urlRerouteOnly: true,
});
